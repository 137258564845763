import SenprimaLogoGray from '../images/logoSenprimaGray.svg'
import shoppingBagIcon from '../images/icons/shoppingBag.svg'
import careIcon from '../images/icons/care.svg'
import showerIcon from '../images/icons/shower.svg'
import houseIcon from '../images/icons/house.svg'
import bandAidIcon from '../images/icons/bandAid.svg'
import brainIcon from '../images/icons/brain.svg'
import handIcon from '../images/icons/hand.svg'
import cleaningIcon from '../images/icons/cleaning.svg'
import caringIcon from '../images/icons/caring.svg'
import telephone2Image from '../images/telephone2.svg'
import footerLogo from '../images/logoSenprimaWhite.svg'
import tabsImage01 from '../images/tabs/wateringTheFlowers.png'
import tabsImage02 from '../images/tabs/walk01.png'
import tabsImage03 from '../images/tabs/care01.png'
import tabsImage04 from '../images/tabs/walk02.png'
import tabsImage05 from '../images/tabs/care02.png'
import logo from '../images/logoSenprimaWhite.svg'
import facebookIcon from '../images/facebookIcon.svg'
import linkedinIcon from '../images/linkedinIcon.svg'
import instagramIcon from '../images/instagramIcon.svg'
import grayPhone from '../images/grayPhone.svg'
import blackPhone from '../images/blackPhone.webp'
import phoneCallImage0 from '../images/steps/phoneCall01.webp'
import phoneCallImage1 from '../images/steps/phoneCall02.webp'
import visitImage from '../images/steps/visit.webp'
import advisingImage from '../images/steps/advising.webp'
import signingImage from '../images/steps/signing.webp'
import arrowPointingDown0 from '../images/steps/arrowPointingDown0.svg'
import arrowPointingDown1 from '../images/steps/arrowPointingDown1.svg'
import arrowPointingDown2 from '../images/steps/arrowPointingDown2.svg'
import playingChildrenImage from '../images/playingChildren.webp'
import walkWithGrandma from '../images/walkWithGradma.webp'

export const welcomingSectionData = {
  alt: 'grandma',
  header: 'Willkommen',
  body: 'Mit uns leben Senioren besser und länger in Ihrem gewohnten Umfeld! \n' +
    'Senprima ist ein ambulanter Pflegedienst und Betreuungsdienst, der sich auf \n' +
    'Seniorenbetreuung zuhause spezialisiert hat.',
  buttons: [{
    label: 'KUNDEN',
    smallScreenLabel: 'Kundenleichtungen',
    href: '#'
  }, {
    label: 'KARRIERE',
    smallScreenLabel: 'Karriere',
    href: '#'
  }],
  information: 'Unser betreuungsangebot is auch in corona - zeiten gesichert!'
}

export const opinionsData = {
  header: 'Was sagen unsere Kunden über uns...?',
  percentRating: '97%  der Kunden wurden uns weiterempfehlen',
  rating: 4,
  numberOfOpinions: '17',
  buttonLabel: 'Jetzt bewerten',
  buttonHref: 'https://www.google.com/maps/place/Senprima+GmbH+Karlsruhe/@48.9937403,8.4559432,17z/data=!4m7!3m6!1s0x479709eae2d8ce25:0x6005dbfb36cce4d1!8m2!3d48.9937403!4d8.4581319!9m1!1b1',
  opinions: [
    {
      name: 'Marie Juana',
      opinion: 'Über die ambulante Pflege von Sen Prima in Karlsruhe kann ich nur positives berichten!\n' +
        'Mein Vater wurde von den zuverlässigen und herzlichen Pflegekräften stets wertschätzend betreut. Auch bei Fragen rund um die Abrechnung konnte bei Sen Prima immer jemand helfen. Ich kann diesen Pflegedienst rundum empfehlen und wäre es nicht zu einem Umzug ins Pflegeheim gekommen, wären wir auch jetzt noch gerne Kunde.',
      rating: 5
    },
    {
      name: 'Christiane Schraml',
      opinion: 'Unsere Mutter wurde von SenPrima betreut und wir können sie auf jeden Fall weiterempfehlen. Sehr zuverlässig und freundlich.',
      rating: 5
    },
    {
      name: 'Alperen Fazlioglu',
      opinion: 'Ein sehr professioneller Dienstleister! Aufgrund meiner Arbeit fehlt mir die Zeit, sich um meine Eltern zu kümmern. Aber aufgrund der Senprima GmbH, kann ich meine Eltern beruhigt der Firma anvertrauen. Von der Brratung bis zur Durchführung läuft alles nach Plan. Zuverlässigkeit, Vertrauen und Kommunikation passen hier sehr gut zusammen. Danke für den Service, die Hilfen und vorallem die vielen Gespräche am Anfang der Betreuung!',
      rating: 5
    }, {
      name: 'actioVITA Jannek Schäfer',
      opinion: 'SenPrima ist ein junger Kooperationspartner im Bereich der stundenweise Betreuung für Familien und Senioren, welches wir zu unserer vollsten Zufriedenheit weiterempfehlen können! Zuverlässig, erreichbar, freundlich und was mir besonders gefällt ist das lösungsorientierte Mitdenken individueller Kundenwünsche. Weiterhin auf eine gute Zusammenarbeit.',
      rating: 5
    },
    {
      name: 'Edith Muller',
      opinion: 'Meine Mutter ist sehr gut bei Ihnen aufgehoben. Sie erzählt mir immer wie freundlich Ihre Betreuungskraft ist und wie gut Sie sich verstehen. Zum Glück gibt es mal einen Pflegedienst der eine 1 zu 1 Betreuung anbietet. Vielen Dank!',
      rating: 5
    },
    {
      name: 'Dominik',
      opinion: 'Habe seit langem einen Pflegedienst gesucht der meine Eltern betreut. Sie wurden Diesbezüglich von jedem Pflegedienst in der Umgebung strickt abgelehnt, da sie anscheinend keine Patienten mehr aufnehmen können. Durch eine Empfehlung von einem Nachbarn, habe ich Kontakt mit der Seniorica GmbH aufgenommen und bin seit dem super zufrieden. Meine Eltern haben inzwischen eine richtige Freundschaft mit ihrer Betreuungskraft entwickeln können. Ich habe jetzt auch mal mehr Zeit für mich selber und kann abschalten weil ich mich auf die Seniorica verlassen kann!',
      rating: 5
    }
  ]
}

export const iframeWithTextData = {
  image: SenprimaLogoGray,
  alt: 'Senrpima Logo',
  iframeSrc: 'https://www.youtube.com/embed/NcRifDitRnU',
  header: 'Alles wird prima mit SenPrima!',
  headline: 'Profitieren sie von…',
  body: 'Mit uns leben Senioren besser und länger in Ihrem gewohnten Umfeld! Senprima ist ein ambulanter…',
  buttonLabel: 'Prüfen',
  buttonHref: '#'
}

export const stepsData = {
  steps: [
    {
      roundImage: phoneCallImage0,
      roundImageAlt: 'Phone call image',
      attachedImage: phoneCallImage1,
      attachedImageAlt: 'Phone call image',
      arrow: arrowPointingDown0
    },
    {
      roundImage: visitImage,
      roundImageAlt: 'Meeting image',
      arrowOnLeft: true,
      arrow: arrowPointingDown1
    },
    {
      roundImage: advisingImage,
      roundImageAlt: 'Advising image',
      arrow: arrowPointingDown2
    },
    {
      roundImage: signingImage,
      roundImageAlt: 'Signing image'
    }
  ]
}

export const icons = [
  {
    image: shoppingBagIcon,
    alt: 'icon',
    header: 'Einkaufe',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: careIcon,
    alt: 'icon',
    header: 'Begleitung außer Haus',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: showerIcon,
    alt: 'icon',
    header: 'Grundpflege',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: houseIcon,
    alt: 'icon',
    header: 'Betreuung zuhause',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: bandAidIcon,
    alt: 'icon',
    header: 'Häusliche  Krankenpflege\n' +
      'und Verordnungen',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: brainIcon,
    alt: 'icon',
    header: 'Demanzebetreuung zuhause',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: handIcon,
    alt: 'icon',
    header: 'Entlastung pflegender Angehöriger | Verhinderungspflege',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: cleaningIcon,
    alt: 'icon',
    header: 'Haushaltshilfe',
    label: 'Mehr erfahren',
    href: '#'
  }, {
    image: caringIcon,
    alt: 'icon',
    header: 'Kinder-/ Familienbetreuung',
    label: 'Mehr erfahren',
    href: '#'
  }
]

export const introductionData = {
  header: 'Profitieren sie von den Vorteilen unseres ambulanten Pflege und Betreuungsdienstes bei ihnen zuhause!',
  body: `Mit uns leben Senioren besser und länger in Ihrem gewohnten Umfeld! \n 
  Senprima ist ein ambulanter Pflege- und Betreuungsdienst, der sich auf Seniorenbetreuung zuhause spezialisiert hat.`
}

export const gradientContactData = {
  image: telephone2Image,
  alt: '#',
  header: 'Machen Sie einen kostenlosen Beratungstermin zu den Pflegebudgets mit SenPrima aus!',
  phoneNumber: '0721 - 9873220',
  openingTime: '9:00',
  closingTime: '17:00',
  buttonHref: '#',
  buttonLabel: 'Kontaktformular'
}

export const footerData = {
  image: footerLogo,
  alt: 'Senprima logo',
  address: `SenPrima GMBH
  \n Ellmendingerstr. 23 
  \n 76227 Karlsruche`,
  phoneNumber: 'tel: 0 721 - 987 3 220',
  links: [
    { label: 'impressum', href: '#' },
    { label: 'datenchutz', href: '#' }
  ],
  socials: [
    {
      image: instagramIcon,
      alt: 'instagram icon',
      href: '#'
    },
    {
      image: facebookIcon,
      alt: 'facebook icon',
      href: '#'
    },
    {
      image: linkedinIcon,
      alt: 'linkedin icon',
      href: '#'
    }
  ]
}

export const tabsData = {
  header: 'Pflegegradrechner',
  headline: 'Folgende Budgets stehen zur Verfügung',
  tabs: [
    {
      tabName: 'Pflegegrad 1',
      image: tabsImage01,
      alt: 'grandma',
      header: 'Der Pflegegrad 1 ist der niedrigste Grad aller Pflegegrade. Sie erhalten den Pflegegrad 1, wenn bei Ihnen eine geringe Beeinträchtigung der Selbstständigkeit festgestellt wird.',
      services: {
        additionalServices: [
          {
            name: 'Kurzzeitpflege',
            price: '0€ jährlich'
          }, {
            name: 'Verhinderungspflege',
            price: '0€ jährlich'
          }, {
            name: 'Pflegehilfsmittel',
            price: '40€ monatlich'
          }
        ],
        basicServices: [
          {
            name: 'Pflegesachleistungen',
            price: '0€ monatlich'
          }, {
            name: 'Entlastungsleistungen',
            price: '125€ monatlich'
          }, {
            name: 'Pflegegeld',
            price: '0€ monatlich'
          }
        ]
      }
    }, {
      tabName: 'Pflegegrad 2',
      image: tabsImage02,
      alt: 'grandma',
      header: 'Den Pflegegrad 2 erhält man mit einem geringeren Zeitaufwand an Pflege. Sie erhalten den Pflegegrad 2, wenn bei Ihnen eine erhebliche Beeinträchtigung der Selbstständigkeit festgestellt wird.',
      services: {
        additionalServices: [
          {
            name: 'Kurzzeitpflege',
            price: '806€ jährlich'
          }, {
            name: 'Verhinderungspflege',
            price: '1.612€ jährlich'
          }, {
            name: 'Pflegehilfsmittel',
            price: '40€ monatlich'
          }
        ],
        basicServices: [
          {
            name: 'Pflegesachleistungen',
            price: '689€ monatlich'
          }, {
            name: 'Entlastungsleistungen',
            price: '125€ monatlich'
          }, {
            name: 'Pflegegeld',
            price: '316€ monatlich'
          }
        ]
      }
    }, {
      tabName: 'Pflegegrad 3',
      image: tabsImage03,
      alt: 'grandma',
      header: 'Den Pflegegrad 3 erhält man mit einem höheren Zeitaufwand an Pflege. Sie erhalten den Pflegegrad 3, wenn bei Ihnen eine schwere Beeinträchtigung der Selbstständigkeit festgestellt wird.',
      services: {
        additionalServices: [
          {
            name: 'Kurzzeitpflege',
            price: '806€ jährlich'
          }, {
            name: 'Verhinderungspflege',
            price: '1.612€ jährlich'
          }, {
            name: 'Pflegehilfsmittel',
            price: '40€ monatlich'
          }
        ],
        basicServices: [
          {
            name: 'Pflegesachleistungen',
            price: '1.298€ monatlich'
          }, {
            name: 'Entlastungsleistungen',
            price: '125€ monatlich'
          }, {
            name: 'Pflegegeld',
            price: '545€ monatlich'
          }
        ]
      }
    }, {
      tabName: 'Pflegegrad 4',
      image: tabsImage04,
      alt: 'grandma',
      header: 'Den Pflegegrad 4 erhält man mit einem sehr hohen Zeitaufwand an Pflege. Sie erhalten den Pflegegrad 4, wenn bei Ihnen eine schwerste Beeinträchtigung der Selbstständigkeit festgestellt wird.',
      services: {
        additionalServices: [
          {
            name: 'Kurzzeitpflege',
            price: '806€ jährlich'
          }, {
            name: 'Verhinderungspflege',
            price: '1.612€ jährlich'
          }, {
            name: 'Pflegehilfsmittel',
            price: '40€ monatlich'
          }
        ],
        basicServices: [
          {
            name: 'Pflegesachleistungen',
            price: '1.612€ monatlich'
          }, {
            name: 'Entlastungsleistungen',
            price: '125€ monatlich'
          }, {
            name: 'Pflegegeld',
            price: '728€ monatlich'
          }
        ]
      }
    }, {
      tabName: 'Pflegegrad 5',
      image: tabsImage05,
      alt: 'grandma',
      header: 'Der Pflegegrad 5 ist der höchste Grad aller Pflegegrade. Sie erhalten den Pflegegrad 5, wenn bei Ihnen eine schwerste Beeinträchtigung der Selbstständigkeit mit besonderen Anforderungen an die pflegerische Versorgung festgestellt wird.',
      services: {
        additionalServices: [
          {
            name: 'Kurzzeitpflege',
            price: '806€ jährlich'
          }, {
            name: 'Verhinderungspflege',
            price: '1.612€ jährlich'
          }, {
            name: 'Pflegehilfsmittel',
            price: '40€ monatlich'
          }
        ],
        basicServices: [
          {
            name: 'Pflegesachleistungen',
            price: '1.995€ monatlich'
          }, {
            name: 'Entlastungsleistungen',
            price: '125€ monatlich'
          }, {
            name: 'Pflegegeld',
            price: '901€ monatlich'
          }
        ]
      }
    }
  ]
}

export const contactSectionData = {
  image0: blackPhone,
  alt0: 'Phone icon',
  image1: grayPhone,
  alt1: 'Phone icon',
  timeText: 'Rufen Sie uns an:',
  openingTime: '9:00',
  closingTime: '17:00',
  phoneNumber: '0721 - 9873220',
  locationText: 'Oder finden Sie den nächsten Standort:',
  placeholder: 'PLZ oder ort...'
}

export const navbarData = {
  image: logo,
  alt: 'Senprima logo',
  links: [
    {
      label: 'KUNDEN',
      href: '#'
    }, {
      label: 'KARRIERE',
      href: '#'
    }, {
      label: 'FRANCHISE',
      href: '#'
    }, {
      label: 'UBER UNS',
      href: '#'
    }, {
      label: 'KONTAKT',
      href: '#'
    }
  ]
}

export const imprintData = {
  header: 'Impressum',
  imprintParts: [
    {
      header: 'Angaben gemäß § 5 TMG',
      body: `SenPrima GmbH
            Ellmendinger Str. 23
            76227 Karlsruhe
            \n
            Handelsregister: HRB 730438
            Registergericht: Mannheim
            \n
            Vertreten durch:
            Sven Eiwen (Geschäftsführer)`
    },
    {
      header: 'Kontakt',
      phoneNumber: '0800 3100800 (kostenlos)',
      faxNumber: '+49 721 987322 99',
      email: 'info@senprima.de',
      type: 'contact'
    },
    {
      header: 'Umsatzsteuer-ID',
      body: `Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
             DE318912570`
    },
    {
      header: 'Angaben zur Berufshaftpflichtversicherung',
      body: `Name und Sitz des Versicherers:
             LVM Versicherung
             48126 Münster
             \n
             Geltungsraum der Versicherung:
             Deutschland`
    },
    {
      header: 'EU-Streitschlichtung',
      paragraphElements: [
        {
          text: 'Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:'
        },
        {
          isLink: true,
          text: 'https://ec.europa.eu/consumers/odr/'
        },
        {
          text: 'Unsere E-Mail-Adresse finden Sie oben im Impressum.'
        }
      ],
      type: 'paragraphsWithLinks'
    },
    {
      header: 'Verbraucherstreitbeilegung/Universalschlichtungsstelle',
      body: `Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
             Verbraucherschlichtungsstelle teilzunehmen.`
    },
    {
      header: 'Haftung für Inhalte',
      body: `Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
             allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
             verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
             Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
             Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
             Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
             Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.`
    },
    {
      header: 'Haftung für Links',
      body: `Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
             Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
             verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
             Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
             Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
             Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
             umgehend entfernen.`
    },
    {
      header: 'Urheberrecht',
      body: `Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
             Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
             Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
             Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
             Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
             beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
             Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
             Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.`
    },
    {
      header: 'Quellenangaben der verwendeten Bilder',
      body: `Adobe/Stock`
    },
  ]
}

export const personalDataProtectionData = {
  header: 'Datenschutzerklärung',
  points: [
    {
      header: 'Datenschutz auf einen Blick',
      body: [
        `Allgemeine Hinweise
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
      \n
      Datenerfassung auf dieser Website 
      Wer ist verantwortlich für die Datenerfassung auf dieser Website? Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
      \n
      Wie erfassen wir Ihre Daten?
      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. 
      Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
      \n
      Wofür nutzen wir Ihre Daten?
      Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
      \n
      Welche Rechte haben Sie bezüglich Ihrer Daten?
      Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
      Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.`
      ]
    },
    {
      header: 'Hosting',
      body: [
        `Wir hosten die Inhalte unserer Website bei folgendem Anbieter: Strato.
        Anbieter ist die Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin (nachfolgend „Strato“). Wenn Sie unsere Website besuchen, erfasst Strato verschiedene Logfiles inklusive Ihrer IP-Adressen. 
        Weitere Informationen entnehmen Sie der Datenschutzerklärung von Strato:https://www.strato.de/datenschutz/. 
        Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        \n
        Auftragsverarbeitung
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.`
      ]
    },
    {
      header: 'Allgemeine Hinweise und Pflichtinformationen',
      companyInfoArray: [
        {
          body: `\nSenPrima GmbH
          Ellmendinger Str. 23
          76227 Karlsruhe
          Deutschland`,
          phoneNumber: '0800-3100800',
          phoneNumberText: ' 0800-3100800',
          email: 'info@senprima.de'
        },
        {
          body: `Datenschutzbeauftragter
          Wir haben einen Datenschutzbeauftragten benannt.
          SenPrima GmbH
          z.Hd. Datenschutzbeauftragter
          Ellmendinger Str. 23
          76227 Karlsruhe`,
          phoneNumber: '0800-3100800',
          phoneNumberText: ' 0800-3100800 (kostenlos)',
          email: 'datenschutz@senprima.de'
        }
      ],
      body: [
        `Datenschutz 
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich
          \n
          Hinweis zur verantwortlichen Stelle 
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:`, `
         Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail- Adressen o. Ä.) entscheidet.
          \n
          Speicherdauer
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
          \n
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
          Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
          Absätzen dieser Datenschutzerklärung informiert.
          \n
          `, `
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          \n
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          \n
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO) WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
          \n
          Beschwerderecht bei der zuständigen Aufsichtsbehörde Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          \n
          Recht auf Datenübertragbarkeit Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          \n
          Auskunft, Berichtigung und Löschung
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
          \n
          Recht auf Einschränkung der Verarbeitung
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
          \n
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          \n
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
          \n
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          \n
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          \n
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
          \n
          SSL- bzw. TLS-Verschlüsselung
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
          \n
          Widerspruch gegen Werbe-E-Mails
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
          `
      ]
    },
    {
      header: 'Datenerfassung auf dieser Website',
      body: [
        `Server-Log-Dateien
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-LogDateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
        Browsertyp und Browserversion
        verwendetes Betriebssystem
        Referrer URL
        Hostname des zugreifenden Rechners
        Uhrzeit der Serveranfrage
        IP-Adresse
        \n
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.
        \n
        Kontaktformular
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
        Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        \n
        Anfrage per E-Mail, Telefon oder Telefax
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
        \n
        Jotform
        Wir haben Jotform auf dieser Website eingebunden. Anbieter ist die Jotform Inc., 111 Pine St. Suite, 1815 San Francisco, Kalifornien 94111, USA (nachfolgend Jotform).
        Jotform ermöglicht es uns, Online-Formulare zu erstellen um Nachrichten, Anfragen und sonstige Eingaben unserer Websitebesucher zu erfassen. Alle von Ihnen getätigten Eingaben werden auf den Servern von Jotform verarbeitet.
        Der Einsatz von Jotform erfolgt auf Grundlage unseres berechtigten Interesses an einer möglichst nutzerfreundlichen Ermittlung Ihres Anliegens (Art. 6 Abs. 1 lit. f DSGVO) Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        Die von Ihnen im Formular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        Die Datenübertragung in die USA wird durch EU-Standardvertragsklauseln abgesichert, die wir mit Jotform abgeschlossen haben. Details finden Sie hier: https://www.jotform.com/gdpr-compliance/dpa/.
        \n
        Auftragsverarbeitung
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
        Weisungen und unter Einhaltung der DSGVO verarbeitet.
        `
      ]
    },
    {
      header: 'Eigene Dienste',
      body: [
        `Umgang mit Bewerberdaten
        Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per E-Mail, postalisch oder via OnlineBewerberformular). Im Folgenden informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt werden.
        \n     
        \n  
        Umfang und Zweck der Datenerhebung
        Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen personenbezogenen Daten (z. B. Kontakt- und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die Begründung eines Beschäftigungsverhältnisses erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb unseres Unternehmens ausschließlich an Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind. Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf Grundlage von § 26 BDSG und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen gespeichert.
        \n
        Aufbewahrungsdauer der Daten
        Sofern wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten wir uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder Zurückziehung der Bewerbung) bei uns aufzubewahren.
        Anschließend werden die Daten gelöscht und die physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden (z. B. aufgrund eines drohenden oder anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn der Zweck für die weitergehende Aufbewahrung entfällt. Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung entgegenstehen. 
        \n
        Stand 01.04.2023
        `
      ]
    }]
}

export const serviceData = [{
  image: {
    src: playingChildrenImage,
    alt: 'Playing children'
  },
  serviceIndex: 8,
  header: 'Kinder-/Familienbetreuung',
  leftSideContentParts: [
    {
      header: 'Kinderbetreuung und Familienpflege bei Krankheit',
      description: `Unser ambulanter Pflege- und Betreuungsdienst 
      \nSenPrima steht Ihnen zur Seite bei Krankheit, 
      \nunerwarteten Krankenhausaufenthalten oder 
      \nAbwesenheit eines Elternteils.`
    },
    {
      headline: `Abrechnung über die
      \n Krankenkasse möglich`,
      description:
        `Zusammen mit dem behandelnden Arzt 
        \nhaben Sie die Möglichkeit, Ihren Bedarf an 
        \nUnterstützung (z. B. eine Haushaltshilfe oder 
        \nKinderbetreuung) beiIhrer Krankenkasse zu
        \nbeantragen. Bei Erkrankung der
        \nHaupterziehungsperson von Kindern bis zu
        \n12 Jahren
        (bei manchen Krankenkassen bis zu 14 
        \nJahren) können zwischen 10 und 40
        \n Wochenstunden für die Familienbetreuung
        \noder Familienpflege durch die Krankenkasse 
        \ngenehmigt werden.`,
      orangeHeadline: true
    },
    {
      header: 'Betreuung autistischer und behinderter Kinder',
      description:
        `Wir wissen um die Wichtigkeit von Entlastung der Eltern,
      \n deshalb übernehmen unsere speziell geschulten
      \n SenPrima Betreuer die Betreuung autistischer und 
      \ngeistig und/oder körperlich 
      \nbehinderter Kinder.`
    }
  ],
  rightSideContentParts: [
    {
      headline: `Wann immer Ihre Familie Hilfe benötigt, unterstützen 
      \nwir Sie mit folgenden Leistungen:`,
      bulletPoints: [
        'Sicherstellung des Kindergarten- und Schulbesuchs',
        'Betreuen der Hausaufgaben',
        'Begleitung zu Freizeitaktivitäten',
        'Haushaltshilfe',
        'Erledigung von Einkäufen',
        'Vor- und Zubereitung von Mahlzeiten',
        'Reinigung und Putzen der Wohnung',
        'Wäsche waschen und bügeln'
      ]
    }
  ]
}, {
  image: {
    src: walkWithGrandma,
    alt: 'walk'
  },
  header: 'Begleitung außer Haus',
  serviceIndex: 1,
  wideBackgroundBar: true,
  leftSideContentParts: [
    {
      headline: `Mobilität bedeutet Unabhängigkeit Sie bestimmen wo die Reise hingeht!`,
      description: `Wir sind der Meinung, dass niemand diese missen sollte! 
      \n Unsere SenPrima Betreuer helfen Ihnen Ihre Mobilität zu 
      \n erhalten oder zu erhöhen. Mit uns können Sie wie 
      \n gewohnt an Ihren Terminen und Veranstaltungen außer 
      \n Haus teilnehmen. Unsere Betreuungskräfte verfügen über 
      \n einen Führerschein und Fahrzeug mit dem sie Sie zu 
      \n Terminen begleiten können:`,
      bulletPoints:
        [
          'Amtsbesuche, Arztbesuche, Friseurtermine',
          'Einkaufsbegleitung',
          'Seniorentreffs, Begleitung zur Kirche oder Friedhof',
          'Gemeinsame Spaziergänge an der frischen Luft'
        ]
    }, {
      headline: `Unser Angebot richtet sich ganz nach 
      \n Ihren Bedürfnissen:`,
      description: `Die Unterstützung im Bereich Mobilität gehört in 
      \n den Bereich Grundpflege und kann von der 
      \n Pflegekasse übernommen werden. SenPrima 
      \n verfügt über eine volle Pflegekassenzulassung
      \n und berät sie gerne über ambulante Budgets der 
      \n Pflegeversicherung für Betreuungsleistungen 
      \n und hauswirtschaftliche Hilfen. Natürlich können 
      \n Sie alle unsere Betreuungsleistungen als 
      \n Privatzahler nutzen.`,
      orangeBorder: true
    }

  ],
  rightSideContentParts: []
}]

export const jobOfferLocations = [
  {
    label: 'Karlsruhe',
    value: 'Karlsruhe'
  },
  {
    label: 'Schwetzingen Heidelberg',
    value: 'Schwetzingen_Heidelberg'
  },
  {
    label: 'Baden - Baden Raststatt',
    value: 'BadenBaden_Raststatt'
  }
]
